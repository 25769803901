<template>
  <div class="columns">
    <div class="column is-4 is-offset-4">
      <section class="section">
        <div class="title has-text-primary">
          Sign in
        </div>

        <b-notification
          v-if="errors.length"
          type="is-danger"
          aria-close-label="Close"
          role="alert"
        >
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </b-notification>

        <form @submit="handleLogin">
          <b-field label="Email" custom-class="is-large">
            <b-input type="email" v-model="formData.email" size="is-large"></b-input>
          </b-field>

          <b-field label="Password" custom-class="is-large">
            <b-input type="password" size="is-large" v-model="formData.password" password-reveal></b-input>
          </b-field>

          <b-button type="is-primary" native-type="submit" size="is-medium">Sign in</b-button>
        </form>

        <b-loading :is-full-page="false" v-model="loading"></b-loading>
      </section>
    </div>
  </div>
</template>

<script>
import { Auth } from '@/services/api';
import TokenStorage from '@/services/token';

export default {
  name: 'login',
  data() {
    return {
      errors: [],
      formData: {
        email: null,
        password: null,
      },
      loading: false,
    };
  },
  methods: {
    handleLogin(event) {
      event.preventDefault();

      this.errors = [];
      this.loading = true;

      Auth.login(this.formData.email, this.formData.password)
        .then((tokenData) => {
          TokenStorage.storeToken(tokenData);
          this.$store.dispatch('user/loadProfile').then(() => {
            this.$router.push('/');
          });
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
